/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/*(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
*/

(function($) {
  $(window).load(function() {
    $('.flexslider').flexslider({
      animation: "fade",
      slideshowSpeed: 2000,
      animationSpeed: 1500,
      controlNav: false,
      touch: true
    });

    $('.navigation-toggle > .fa-bars').on('click', function() {
      $('.navigation-primary').toggleClass('visible-xs');
      $('.navigation-primary').toggleClass('visible-sm');
    });
  });
})(jQuery);

(function($) {

  $('.gallery > .gallery-image').on('click', function() {
    openPhotoSwipe($(this).index());
  });

  var buildItems = function() {
    var items = [];
    $('.gallery').find('.gallery-image').each(function( index ) {
      var bg = $(this).css("background-image");
      bg = bg.replace(/.*\s?url\([\'\"]?/, '').replace(/[\'\"]?\).*/, '');

      var image = new Image();
      image.src = bg;

      items.push({
          src: bg,
          w: image.width | 2000,
          h: image.height | 1200
        });
    });
    return items;
  };

  var openPhotoSwipe = function(index) {
    var pswpElement = document.querySelectorAll('.pswp')[0];

    // define options (if needed)
    var options = {
      index: index,
      preload: [2,2]
    };

    var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, buildItems(), options);
    gallery.init();
  };
})(jQuery);

// google-map
function initMap() {

  if (document.getElementById('google-map') ===  null) {
    return;
  }

  var myLatLng = {lat: 54.3607704, lng: 10.132163};
  var map = new google.maps.Map(document.getElementById('google-map'), {
    zoom: 13,
    scrollwheel: false,
    center: myLatLng,
    styles: [{"featureType":"landscape","stylers":[{"hue":"#FFBB00"},{"saturation":43.400000000000006},{"lightness":37.599999999999994},{"gamma":1}]},{"featureType":"road.highway","stylers":[{"hue":"#FFC200"},{"saturation":-61.8},{"lightness":45.599999999999994},{"gamma":1}]},{"featureType":"road.arterial","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":51.19999999999999},{"gamma":1}]},{"featureType":"road.local","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":52},{"gamma":1}]},{"featureType":"water","stylers":[{"hue":"#0078FF"},{"saturation":-13.200000000000003},{"lightness":2.4000000000000057},{"gamma":1}]},{"featureType":"poi","stylers":[{"hue":"#00FF6A"},{"saturation":-1.0989010989011234},{"lightness":11.200000000000017},{"gamma":1}]}]
  });
  var marker = new google.maps.Marker({
    position: myLatLng,
    map: map,
    title: 'Fachpraxis für Kieferorthopädie, Dr. Zacharias Drosos und Kollegen'
  });
}
